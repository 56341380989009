

.error-page {
   width: 100%;
   height: 100%;
   flex-grow: 1;
   position: relative;
   display: flex;
   align-items: center;
   flex-direction: column;
}
.error-inner {
   position: absolute;
   top: 25%;
   h1 {
      font-family: rubik;
      font-size: 3rem;
   }
   h2 {
      font-family: rubik;
      font-size: 2.5rem;
      strong {
         font-weight: 900;
      }
   }
}
.error-edd {
   font-family: copperplate;
   position: absolute;
   bottom: 0; 
   font-size: 1.1rem;
   color: rgb(40, 40, 40);
}
.e-shadow {
   position: absolute;
   width: .1px;
   height: 0px;
}
.esd-1 {
   box-shadow: 0 0 100px 30px rgb(255, 119, 0);
   top: 15%;
   right: 15%;
   width: 30px;
   height: 0;
   transform: rotate(25deg);

}
.esd-2 {
   box-shadow: 0 0 50px 35px rgb(198, 226, 255);
   top: 70%;
   right: 68%;
   width: 90px;
   transform: rotateZ(10deg);
}
.esd-3 {
   box-shadow: 0 0 30px 15px rgb(255, 255, 0);
   top: 90%;
   right: 15%;
}
.esd-4 {
   box-shadow: 0 0 30px 15px rgb(255, 90, 173);
   top: 40%;
   right: 85%;
   height: 25px;
   width: 0;
}
.esd-5 {
   box-shadow: 0 0 40px 15px rgb(170, 255, 0);
   top: 55%;
   right: 30%;
   width: 40px;
}
.esd-6 {
   box-shadow: 0 0 30px 15px rgb(148, 156, 255);
   top: 10%;
   right: 65%;
}
.esd-7 {
   box-shadow: 0 0 30px 15px rgb(234, 201, 71);
   top: 95%;
   right: 90%;
}
.esd-8 {
   box-shadow: 0 0 50px 35px rgb(128, 255, 106);
   top: 15%;
   right: 95%;
}
.esd-9 {
   box-shadow: 0 0 30px 15px rgb(255, 106, 198);
   top: 80%;
   right: 35%;
}
.esd-10 {
   box-shadow: 0 0 50px 35px rgb(220, 194, 255);
   top: 64%;
   right: 5%;
}
