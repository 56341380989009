

@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');


$m-purple: rgb(145, 155, 255);
$item-gray: rgb(245, 245, 245);
$gray45: rgb(245, 245, 245);
$gray40: rgb(240, 240, 240);
$gray35: rgb(235, 235, 235);
$gray30: rgb(230, 230, 230);
$gray25: rgb(225, 225, 225);
$gray00: rgb(200, 200, 200);
$blue1: rgb(55, 101, 255);
$bgreen: rgb(70, 215, 70);

@mixin gray-border-back {
   border: 1px solid #b9b9b9;
   border-radius: 8px;
   box-shadow: 0 3px 10px 1px rgb(215, 215, 215);
}
@mixin blue-border-bottom {
   background-color: transparent;
   border: none;
   border-bottom: 1px solid $ven-blue;
   box-shadow: none;
   border-radius: 0;
}

*,
*::before,
*::after {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
   font-family: rubik, montserrat, 'Microsoft Sans Serif', gotham;
}
a {
   text-decoration: none;
}
h1, h2, h3, h4, h5, h6, pre, p {
   margin-bottom: 0;
}

input {
   font-family: rubik, roboto mono !important;
}

html,
body,
#root {
   height: 100%;
}

.App {
   height: 100%;
}
.main-app {
   margin: 0 auto;
   min-height: 100%;
   display: flex;
   justify-content: space-between;
   flex-direction: column;
}


// // //   LAYOUT   // // //
.navCona {
   background-color: $m-purple;
}
.header {
   margin: 0 auto;
   max-width: 800px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 15px 20px !important;  
   color: white;
   .brand-cona {
      .nav-brand {
         display: inline-block;
         margin-bottom: 0;
         cursor: pointer;
         font-size: 1.8rem;
         font-weight: 600;
         font-family: fira sans;
      }
   }
   .nav-ul-list {
      display: flex;
      margin-bottom: 0;
      list-style: none;
      gap: 20px;
      font-size: 1.3rem;
      cursor: pointer;
   }
   a {
      position: relative;
      color: white;
      text-decoration: none;
      &:hover {
         color: white;
      }
   }
}

.section {
   flex: 1;
   width: 100%;
   max-width: 800px;
   min-height: 100%;
   padding: 15px 2% !important;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
}

.footer {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   padding: 10px 0 !important;
   background-color: darken($m-purple, 3%);
   color: white;
   p, h6:not(:first-child) {
      margin-bottom: 0;
   }
}
.nav-brand, .footer-brand {
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Old versions of Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
   user-select: none;
}

.bt {
   border: none;
   outline: none;
   font-size: 1.1rem;
   padding: 5.5px 15px;
   border-radius: 5px;
   background-color: $m-purple;
   color: white;
   margin: 5px 0;
   &:hover {
      background-color: darken($m-purple, 3%);
   }
}
.bt-click {
   &:active {
      transform: scale(1.05);
   }
}

.cred {
   color: red !important;
}
.cgreen {
   color: rgb(0, 220, 0);
}
.bgreen {
   background-color: $bgreen;
   &:hover {
      background-color: darken($bgreen, 5%);
   }
}
.noget {
   -webkit-user-select: none; /* Safari */
   -ms-user-select: none; /* IE 10 and IE 11 */
   user-select: none; /* Standard syntax */
}
.blink {
   color: black;
   text-decoration: none;
   &:hover {
      color: black;
   }
}
.wlink {
   color: white;
   text-decoration: none;
   &:hover {
      color: white;
   }
}
.active-link::after {
   position: absolute;
   content: "";
   display: block;
   height: 3px;
   width: 100%;
   background-color: white;
   border-radius: 5px;
   transform: translateY(-2px);
}

.all-sign {
   display: flex;
   justify-content: center;
   form {
      @include gray-border-back;
      width: 90%;
      max-width: 700px;
      padding: 50px 50px 40px;
      margin-top: 20px;
      background-color: white;
   }
   .form-title {
      transform: translateY(-15px);
   }
}

.acform-title {
   margin-bottom: 15px;
}

.footer__signout {
   cursor: pointer;
   &:hover {
      text-decoration: underline;
   }
}


.transition-item-enter {
   transform: translateX(100px);
   opacity: 0;
}
.transition-item-enter-active {
   opacity: 1;
   transform: translateX(0);
   transition: all .4s ease-out;
}
.transition-item-exit {
   opacity: 1;
   transform: translateX(0);
}
.transition-item-exit-active {
   opacity: 0;
   transform: translateX(-100px);
   transition: all .4s;
}


@media (min-width: 600px) {
   .header {
      justify-content: center;
      gap: 200px;
   }   
}
@media (min-width: 700px) {
   .header {
      justify-content: center;
      gap: 250px;
   }   
}
@media (min-width: 800px) {
   .header {
      justify-content: center;
      gap: 300px;
   }   
}