

@import "esta.scss";

.acmodal {
   position: fixed;
   left: 0;
   right: 0;
   bottom: 0;
   top: 0;
   background-color: rgba(0, 0, 0, .55);
   z-index: 1000;
   display: flex;
   justify-content: center;
   align-items: center;
}

.acmodal__content {
   width: 340px;
   position: absolute;
   top: 10%;
   padding: 30px 20px;
   background-color: white;
   border: 1px solid rgb(215, 215, 215);
   border-radius: 12px;
   animation: show .3s ease;
}


@keyframes show {
   0% {
      opacity: 0;
      transform: translateY(-150px);
   }
   100% {
      opacity: 1;
      transform: translateY(0);
   }
}